.error-modal {
  min-height: 450px;

  .modal__body {
    .modal__body-content {
      padding-bottom: 40px;
    }

    min-height: 250px;
  }
}
