.connector-card {
  p {
    line-height: 1.2;
    text-align: left;
  }

  &__tags {
    &--pc,
    &--dc {
      display: flex;
    }

    p {
      display: inline-block !important;
      padding-right: 20px;
      margin: 0;
    }
  }

  .unkownAvailability {
    flex-direction: column;
    align-items: start;
  }
}

html[data-theme='dark-retail'] {
  .connector-card {
    color: var(--font-color-primary);
  }
}
