.location-info {
  color: var(--ca-retail-primary);

  .value {
    color: var(--ca-retail-primary);
    padding-left: 20px;
    font-weight: 700;
  }
}

.ca-available-charger-count {
  align-items: center;
  display: flex;
  gap: 1rem;
}

.location-arrived {
  button {
    padding: 6px 36px 4px;
    box-shadow: none;
  }
}

.address-container {
  color: var(--ca-retail-primary);
  display: flex;
  flex-direction: column;

  h3 {
    margin-bottom: 0;
  }

  .tag--plug-n-charge {
    width: 264px;
    height: 42px;
    font-weight: 400;
  }
}

.header-label {
  .go-back-img {
    margin-bottom: 2px;
  }
}
