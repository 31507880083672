.price-info-modal {
  min-height: 250px;
  max-height: fit-content;
  padding: 20px 40px;

  .modal__body {
    min-height: 200px;
    max-height: 450px;
  }
}
