.ca-retail-header {
  background: radial-gradient(
      61.71% 137.64% at 50% 91.01%,
      rgb(9 47 88 / 85%) 0%,
      rgb(21 92 168 / 85%) 0.01%,
      rgb(12 63 120 / 0%) 100%
    ),
    #020f1b;
  height: 267px;
  padding: 50px 70px 50px 90px;

  .ca-retail-title {
    font-size: 70px;
    display: grid;
    grid-template-columns: 60px auto 50px;
    align-items: center;
    color: var(--ca-retail-white);
    margin-bottom: 55px;
    grid-gap: 0.5rem;
  }

  .ca-retail-subtext {
    font-size: 31px;
    color: var(--ca-retail-white);
  }
}

.ca-retail-nearybycharges-container {
  display: grid;
  grid-template-columns: auto 240px;
  align-items: center;
}

.refreshbutton-container {
  display: flex;
  align-items: center;
  gap: 20px;
  color: $mutedBlue;
}

.listAndMapViewSwitch {
  display: flex;
  gap: 32px;
  justify-content: center;
  align-items: center;
}

html[data-theme='dark-retail'] {
  .refreshbutton-container {
    color: var(--font-color-primary);
  }

  .app-title {
    color: $primaryBlueDarkMode;
  }

  .distance-unit-selected {
    color: $primaryBlueDarkMode;
  }
}

.quick-filters-section {
  display: grid;
  grid-template-columns: 1fr 4fr 2fr;
  margin: 16px 0;
}

.navigation-icon {
  height: 40px;
  padding-left: 15px;
  padding-bottom: 8px;
  align-items: center;
}

.star_header {
  margin-left: -17px;
  margin-right: -17px;
  transform: scale(0.65);
}

.location-card__score {
  display: grid;
  justify-content: center;
}

.location-header-button {
  text-transform: none;
  padding: 9px 50px;
}

.score_alone {
  padding-right: 10px;
}
