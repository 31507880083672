.slider-outer-border {
  width: 100%;
  height: 8px;
  margin-bottom: 25px;
}

.slider-range {
  @extend .text-base--34;

  font-size: 26px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  color: $darkGrey;
}

.MuiSlider-thumb {
  background: url('../../../../common/assets/images/slider_thumb.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.clearence-title {
  color: var(--background-color-tertiary);
}

.clearence-helpertext {
  width: 100%;
  margin: auto;
  text-align: center;
  color: $darkGrey;
  font-size: 30px;
}

.clearence-value {
  color: #102b4e;
}

html[data-theme='dark-retail'] {
  .clearence-helpertext {
    color: $secondaryGrayDarkMode;
  }

  .clearence-value {
    color: $secondaryBlueDarkMode;
  }

  .slider-range {
    color: $primaryBlueDarkMode;
  }
}
