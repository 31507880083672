.unable-to-active-error-modal {
  --scrollbarBG: #cfd8dc;
  --thumbBG: var(--ca-retail-primary);

  width: 85% !important;
  max-width: 85%;
  height: 50% !important;
  max-height: 50% !important;
  padding: 0 30px !important;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  overflow: hidden !important;
  position: fixed !important;
  top: 6% !important;

  .unable-to-active-error-modal-body {
    padding: 20px;
    overflow: hidden auto;
    max-width: 104%;
    max-height: 80%;
  }

  & ::-webkit-scrollbar {
    width: 10px;
  }

  & ::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
    border-radius: 10px;
  }

  & ::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 10px;
  }
}

.unable-to-active-error-modal-title {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: $base-font-condensed;
  color: var(--help-modal-header-text);
  font-size: 40px;
  line-height: 52px;
  margin-top: 18px;
}

.unable-to-active-error-modal-title-background-image {
  opacity: 0.3;
  width: -webkit-fill-available;
  height: 35px;
}

.payments-icon-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 17px;
}

.bocn-subscription-info {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  color: var(--help-modal-header-text);
  font-size: 36px;
  font-family: $base-font-condensed;
  letter-spacing: 0.571px;
  margin-bottom: 32px;
}

.unable-to-active-error-modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media screen and (width >= 1311px) {
  .unable-to-active-error-modal {
    height: 80% !important;
    max-height: 80% !important;
  }
}

.unable-to-active-error-modal-footer .unable-to-active-error-modal-close-btn {
  margin-top: 0;
}
