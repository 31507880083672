.help-modal-retail {
  --scrollbarBG: #cfd8dc;
  --thumbBG: var(--ca-retail-primary);

  width: 85% !important;
  max-width: 85%;
  height: 88% !important;
  max-height: 88% !important;
  padding: 0 30px !important;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  overflow: hidden !important;
  position: fixed !important;
  top: 6% !important;

  .help-modal-body-retail {
    padding: 0 40px;
    overflow-y: auto;
    max-height: calc(100% - 200px);
  }

  & ::-webkit-scrollbar {
    width: 10px;
  }

  & ::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
    border-radius: 10px;
  }

  & ::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 10px;
  }
}

.help-modal-header-retail {
  @extend .modal-header-sticky;

  box-shadow: var(--modal-header-shadow);
  display: grid;
  justify-content: center;
  padding: 10px 0;
}

.help-modal-title {
  align-self: center;
  text-align: center;
}

.instruction-segment {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 15px 0;

  &__bottom {
    margin-bottom: 60px;
  }
}

.instruction-image {
  width: 200px;
  height: 100%;
  margin-left: 10px;
  margin-right: 60px;

  &__bottom {
    width: 42%;
  }
}

.instruction-text {
  font-family: $base-font-condensed;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: var(--help-modal-header-text);
  width: 60%;
  text-indent: -25px;
  margin-left: 25px;
}

.instruction-title {
  line-height: 51px;
  letter-spacing: 2.57px;
}

.modal-summary-text {
  display: inherit;
  line-height: 34px;
  letter-spacing: 2.21px;
}

.instruction-body {
  line-height: 34px;
  letter-spacing: 2.21px;
}

.version-number {
  font-weight: bold;
  text-align: end;
}

html[data-theme='dark-retail'] {
  div[data-id='stepOne-plugin'] {
    padding-top: 36px;
  }
}

.help-modal-sub-title-1 {
  color: var(--help-modal-header-text);
  font-family: $base-font-condensed;
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
}

.help-modal-sub-title-2 {
  color: var(--help-modal-header-text);
  font-family: $base-font-condensed;
  font-style: normal;
  font-weight: 400;
  font-size: 36px !important;
  line-height: 40px;
  margin: 25px 0;
}

.instruction-color {
  background: #f3f2fb;
  border-radius: 10px;
  position: relative;
  width: 40%;
}

.instruction-activate {
  background: var(--help-modal-instruction-block-bg);
  border-radius: 10px;
  margin-right: 24px;
}

.instruction-fleet {
  background: var(--help-modal-instruction-block-bg);
  border-radius: 10px;
}

.instruction-block {
  background: var(--help-modal-instruction-block-bg);
  border-radius: 8.19px;
}

.instruction-image-retail {
  margin: 40px 0 0 22px;
  width: 88%;
  height: 34%;
}

.instruction-text-retail {
  font-family: $base-font-condensed;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  color: var(--help-modal-header-text);
  margin: 40px 0 0 22px;
  text-indent: -25px;
}

.activate-qr-image {
  width: 100%;
}

.activate-qr-text {
  color: var(--help-modal-header-text);
  font-family: $base-font-condensed;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.fleet-customers-text {
  color: var(--help-modal-header-text);
  font-family: $base-font-condensed;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.horizontal-space {
  width: 0;
}

.close-btn-help-modal {
  @extend .button--secondary;

  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 75px;
  justify-content: center;
  margin-bottom: 30px;
  width: 300px;
  background: linear-gradient(180deg, #0142bd 0%, #0275eb 100%);
  border-radius: 60px;
  font-family: $base-font-condensed;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 55px;
  align-items: center;
  text-align: center;
  color: #fff;
  text-transform: capitalize;
}

.center-button {
  display: grid;
  justify-content: center;
}

.help-text {
  font-family: $base-font-condensed;
  font-style: normal;
  font-weight: 400;
  font-size: 31px;
  line-height: 33px;
  color: var(--help-modal-header-text);
}

.alignment-text {
  padding: 2% 0% 0% 4%;
}

.clear-button-activate {
  color: #0142bd !important;
}

@media screen and (width >= 1311px) {
  .help-modal-retail {
    max-height: 82% !important;
  }
}
