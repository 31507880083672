.apply-filters-modal {
  --scrollbarBG: #cfd8dc;
  --thumbBG: var(--font-color-secondary);

  width: 88% !important;
  max-width: 88%;
  height: 68% !important;
  max-height: 68% !important;
  padding: 0 30px;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  display: flex;
  flex-direction: column;

  .modal-body {
    overflow: hidden auto;
    max-width: 94%;
    padding-right: 3.75rem;
  }

  .modal-footer {
    position: relative;
    width: 100%;
    padding: 1.25rem;
  }

  & ::-webkit-scrollbar {
    width: 10px;
  }

  & ::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
    border-radius: 10px;
  }

  & ::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 10px;
  }
}

.filters-icons {
  height: 60px;
  width: 60px;
  margin-left: -10px;
  margin-right: 4px;
}

.filters-icons-small {
  height: 38px;
  width: 38px;
  margin-right: 17px;
}

.filter-tag-text {
  @extend .tag--in-network-tag;

  margin-right: 16px;
  max-width: 400px;
  height: 44px;
  padding: 4px 20px;
  overflow: hidden;
}

.filter-checkbox {
  float: right;
  width: 30px;
  height: 30px;
}

.filtersgroup-container {
  border-bottom: 2px solid rgb(68 81 111 / 30%);
  padding: 0;
}

.filter-selection-row {
  padding: 15px 0;
  justify-content: center;
  align-items: center;
}

.filter-selection-row-desc {
  color: $stallsLightMode;
  font-size: 28px;
  margin: 6px 0 0;
}

.filters-checkbox {
  padding: 16px 24px 0;
}

.clear-filters {
  @extend .text-blue;

  text-decoration: underline;
  line-height: 55px;
}

.filters-modal-container {
  padding: 0;
}

.filters-accordion {
  button {
    background: none;
    border: none;
    float: right;
  }
}

.filters-modal-header {
  @extend .modal-header-sticky;

  background-color: var(--background-color-primary);
  box-shadow: var(--modal-header-shadow);
  position: relative;
  margin: 0 20px;
}

.cross-icon {
  height: 60px;
  width: 60px;
  align-self: center;
}

.rating-text {
  margin-top: 22px;
  white-space: nowrap;
}

.rating-filter {
  display: flex;
  justify-content: space-between;
}

.rating-icon {
  height: 80px;
}

.star {
  margin-left: 20px;
  margin-right: 20px;
}

.charger-speed-row {
  display: grid;
  grid-template-columns: 55px 1fr;
  padding: 32px 0;

  .filter-text {
    text-transform: none !important;
  }
}

.trailer-access-row {
  display: grid;
  grid-template-columns: 55px 1fr;
  padding: 10px 0;
}

.filters-accordion-toggle {
  width: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 12px;
}

.icon-arrow-down {
  margin-bottom: 8px;
}

.icon-arrow-up {
  margin-bottom: -4px;
}

.accordion-row-list > .filtersgroup-container:last-child {
  border-bottom: none;
}

html[data-theme='light-retail'] {
  .filter-tag-text-pc {
    @extend %tag-base;
    @extend %tag-pNc-base;
  }
}

html[data-theme='dark-retail'] {
  .filter-tag-text-pc {
    @extend %tag-base;
    @extend %tag-pNc-base;
  }
}
