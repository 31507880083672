.H_ib_body {
  position: relative !important;
  right: 20vw !important;
  bottom: 11vh !important;

  .H_ib_content {
    margin: 0;
    padding: 1.5rem;
    background-color: var(--background-color-primary);
    font-family: $base-font-condensed !important;

    .map-location-card {
      width: 380px;
      display: flex;
      flex-direction: column;
      background-color: var(--background-color-primary);
      color: var(--font-color-primary);
      font-family: $base-font-condensed;
      font-size: 28px;
      letter-spacing: 2.43px;
      line-height: 1.2;
      margin: 0;
      overscroll-behavior: contain;

      .map-location-card-header {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;

        .tag--connector-speed {
          color: var(--font-color-primary);
        }

        p {
          margin: 0;
        }

        .speeds-label {
          font-size: 30px;
          color: var(--font-color-primary);
          margin-right: 10px;
        }

        .station-label {
          font-size: 32px;
          color: var(--font-color-primary);
          font-weight: 500;
          line-height: normal;
        }

        .station-speed {
          color: var(--font-color-primary);
          font-size: 34px;
        }

        .unavailable-network-image {
          height: 34px;
          margin-right: 12px;
          width: 34px;
        }

        .adapter-needed-image {
          margin-left: 0;
          width: 40px;
          height: 40px;
        }

        .tag--plug-n-charge {
          padding: 1px 0;
        }
      }

      .nav-btn {
        margin-top: 1rem;
      }

      .stall-availability {
        font-size: 34px;
      }

      .ca-available-charger-count {
        font-size: 34px;
      }
    }
  }

  .tag--in-network-tag {
    font-size: 15px !important;
  }
}

.H_ib.H_ib_top .H_ib_tail {
  &::after {
    border: none;
  }
}

.H_ib_close {
  width: 20px;
}

.mapview-container {
  height: 600px;
  border: 2px solid gray;
}

.showmapview-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -1;
}

html[data-theme='dark-retail'] {
  .custom-info-bubble {
    .H_icon {
      fill: var(--font-color-primary);
    }
  }
}
