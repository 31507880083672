$button-min-width: 260px;
$card-margin-bottom-dis: 38px;

.location-card {
  display: flex;
  grid-template-columns: 1fr 270px;
  grid-gap: 1rem;
  justify-content: space-between;
  flex-flow: row wrap;

  .charging-station-label {
    flex-grow: 1;
  }

  p {
    margin-bottom: 0;
  }

  &__title {
    position: relative;

    &-link {
      display: flex;
      padding-top: 8px;
      align-items: flex-start;
      vertical-align: top;
    }
  }

  &__info {
    display: flex;
    padding-left: 50px;
    flex-wrap: wrap;
    gap: 10px;
  }

  &__distance {
    margin-bottom: $card-margin-bottom-dis;
    text-align: center;
    height: 37px;
    color: var(--font-color-primary);
    font-size: 31px;
    letter-spacing: 2.21px;
    line-height: 37px;
    padding-top: 15px;
  }

  &__btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 8px;
    margin-left: auto;
  }

  .location-card__btn .button--primary,
  .button--secondary {
    min-width: $button-min-width;
    padding: 9px 50px !important;
  }

  .icon__green-check {
    width: 14px;
    height: 14px;
    margin-right: 14px;
  }

  .available-network-image {
    width: 38px;
    height: 38px;
    margin-top: 1px;
    margin-right: 17px;
  }

  .no-connector-speed {
    position: relative;
    top: 5px;
  }

  .charger-title-container {
    margin-bottom: 35px;
    margin-top: 13px;
    max-width: 23em;
  }

  .star {
    margin-left: -17px;
    margin-right: -17px;
    transform: scale(0.65);
  }

  .distance-to-charger {
    display: grid;
    justify-content: center;
  }
}

.adapter-needed-image {
  height: 48px;
  width: 48px;
  margin-right: 12px;
  margin-left: 22px;
  margin-bottom: 4px;
}

.adapter-needed-info {
  white-space: nowrap;
  height: 48px;
  font-weight: 400;
}

.available-charger-count {
  display: flex;
  align-items: center;
  justify-content: center;
}

html[data-theme='light-retail'],
html[data-theme='dark-retail'] {
  .location-card {
    .button--secondary {
      min-width: $button-min-width;
      padding: 9px 50px;
    }
  }
}
