.toggle-switch {
  display: inline-block;
  width: 100px;
  height: 50px;
  position: relative;
  border-radius: 50px;
  box-shadow: 0 -10px 10px 0 rgb(255 255 255 / 50%), 0 10px 10px 0 rgb(0 0 0 / 15%);
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider::before {
  position: absolute;
  content: '';
  height: 40px;
  width: 40px;
  left: 7px;
  bottom: 5px;
  background-color: var(--toogle-background-color);
  transition: 0.4s;
  border-radius: 50%;
}

.slider {
  position: absolute;
  inset: 0;
  background-color: $white;
  transition: 0.4s;
  border-radius: 35px;
  box-shadow: inset 0 5px 8px 0 rgb(0 0 0 / 40%), inset 0 -3px 8px 0 rgb(92 156 225 / 25%);
}

input:checked + .slider {
  background: linear-gradient(180deg, #0275eb 0%, #0142bd 100%);
}

input:checked + .slider::before {
  transform: translateX(47px);
}

html[data-theme='dark-retail'] .toggle-switch {
  box-shadow: var(--toogle-switch-boxshadow);
}
