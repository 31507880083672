.bocn-modal {
  width: 80% !important;
  overflow: hidden !important;

  --scrollbarBG: #cfd8dc;
  --thumbBG: var(--ca-retail-primary);

  & ::-webkit-scrollbar {
    width: 10px;
  }

  & ::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
    border-radius: 10px;
  }

  & ::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 10px;
  }

  .modal__header {
    padding: 0 40px;
    display: flex;
    justify-content: center;

    .modal__header-title {
      color: var(--font-color-quinary);
      font-size: 40px;
    }

    height: 90px;
  }

  .modal__body {
    height: calc(100% - 190px) !important;
    overflow: hidden;

    .modal__body-content {
      overflow: auto;

      @extend .text-base--31;
    }

    .scan_info {
      overflow: auto;
      margin-top: 25px;

      @extend .text-base--31;
    }

    .already-member-label {
      font-size: 52px;
      font-style: normal;
      font-weight: 400;
      line-height: 36px;
      color: var(--ca-retail-secondary);
    }

    .bocn-qr-code {
      width: 200px;
    }
  }

  .modal__footer {
    padding: 0 !important;
    height: 100px;
  }

  .expand-network-message {
    color: var(--ca-retail-secondary);
  }
}
